import { useScroll, useTransform } from "framer-motion"
import { RefObject } from "react"

type UseFadeInTransitionArgs = { fadeInDistance?: number; targetRef: RefObject<HTMLElement> }

export const useFadeInTransition = ({ fadeInDistance = 200, targetRef }: UseFadeInTransitionArgs) => {
  const { scrollYProgress } = useScroll({ offset: ["start 0.90", "start 0.3"], target: targetRef })

  const y = useTransform(scrollYProgress, [0, 1], [`${fadeInDistance}px`, "0px"])

  return {
    opacity: scrollYProgress,
    y,
  }
}
