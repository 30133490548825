import dynamic from "next/dynamic"
import useTranslation from "next-translate/useTranslation"
import { FC, useEffect, useState } from "react"

import { Typography } from "@app/shared/components/Typography"
import { SellPillar, SellPillarContent } from "@app/shared/types/website-pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { HomePillarAccordion } from "./Accordion"
import { HomePillarsModal } from "./Modal"
import { homePillars, homePillarsContent, homePillarsPlayer } from "./styles.css"

const { default: lottie } = await import(/* webpackChunkName: "lottieweb" */ "lottie-web")

const LottiePlayer = dynamic(() => import("@app/shared/components/LottiePlayer"))

export const HomePillars: FC = () => {
  const { t } = useTranslation("sell")
  const pillars = t<ReadonlyArray<SellPillar>>("sell:pillars", {}, { returnObjects: true })

  const [currentPillarIndex, setCurrentPillarIndex] = useState(0)
  const [modalContent, setModalContent] = useState<SellPillarContent>()
  const [currentPillarAnimation, setCurrentPillarAnimation] = useState<string>("")

  useEffect(() => {
    setCurrentPillarAnimation("")
    if (!isNaN(currentPillarIndex)) {
      setTimeout(() => {
        setCurrentPillarAnimation(pillars[currentPillarIndex].content.animation)
        lottie.play(currentPillarAnimation)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPillarIndex])

  useEffect(() => {
    !!modalContent
      ? document.body.classList.add("menu-opened")
      : document.body.classList.remove("menu-opened")
    return () => {
      document.body.classList.remove("menu-opened")
    }
  }, [modalContent])

  return (
    <section className={homePillars}>
      <Typography component="h2" variant="h2">
        {t("pillarsHeadline")}
      </Typography>
      <div className={homePillarsContent}>
        <div>
          {pillars.map(({ content, ...accordionProps }, index) => (
            <HomePillarAccordion
              key={index}
              desktopDescription={content.description}
              isOpen={currentPillarIndex === index}
              onToggle={() => {
                const isCurrentPillarAlreadyOpen = currentPillarIndex === index
                if (isCurrentPillarAlreadyOpen) {
                  setCurrentPillarIndex(NaN)
                } else {
                  handleUserEventClick(`ClickOnAccordion0${index + 1}`)
                  setCurrentPillarIndex(index)
                }
              }}
              {...accordionProps}
            />
          ))}
        </div>
        <div className={homePillarsPlayer}>
          {!!currentPillarAnimation && <LottiePlayer loop loopDelay={1500} name={currentPillarAnimation} />}
        </div>
      </div>
      {!!modalContent && <HomePillarsModal {...modalContent} onClose={() => setModalContent(undefined)} />}
    </section>
  )
}
