import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { ChevronRight } from "@app/shared/components/icons/ChevronRight"
import { MyImage } from "@app/shared/components/MyImage"
import { Typography } from "@app/shared/components/Typography"
import theme from "@app/shared/design-lib/style-theme/theme.css"
import { SellGuides } from "@app/shared/types/website-pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import {
  homeCard,
  homeCardTitle,
  homeCardImage,
  homeCardDescription,
  homeCardLink,
  homeCardLinkIcon,
} from "./styles.css"

export const HomeCards: FC = () => {
  const { lang, t } = useTranslation("sell")
  const guides = t<SellGuides>("sell:guides", {}, { returnObjects: true })

  return (
    <section className={homeCard}>
      <figure className={homeCardImage}>
        <MyImage alt="guides" height={200} src={`/images/guides/guides-sell_${lang}.svg`} width={300} />
      </figure>

      <article>
        <Typography className={homeCardTitle} component="h2" variant="bodyL">
          {guides.title}
        </Typography>

        <Typography className={homeCardDescription} color={theme.colors.greyscale600}>
          {guides.description}
        </Typography>

        <Link
          aria-label={guides.cta.title}
          className={homeCardLink}
          href={guides.cta.url}
          onClick={() => handleUserEventClick("GuideCard-ClickReadGuide")}
        >
          <Typography color={theme.colors.greyscale600} component="span" variant="description">
            {guides.cta.title}
          </Typography>
          <ChevronRight className={homeCardLinkIcon} />
        </Link>
      </article>
    </section>
  )
}
