import { useInView, UseInViewOptions } from "framer-motion"
import { useContext, useEffect } from "react"

import { BgColorContext } from "../utils/context/useBgColor"

export const useChangeBgColorInView = (
  targetRef: React.RefObject<HTMLElement>,
  color: string,
  options?: UseInViewOptions
) => {
  const isInView = useInView(targetRef, options)
  const bgColorContext = useContext(BgColorContext)

  useEffect(() => {
    if (bgColorContext && isInView) {
      bgColorContext.setBgColor(color)
    }
  }, [isInView, color])
}
