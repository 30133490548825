import dynamic from "next/dynamic"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { Button } from "@app/shared/components/Button"
import { Close } from "@app/shared/components/icons/Close"
import { Typography } from "@app/shared/components/Typography"
import { SellPillarContent } from "@app/shared/types/website-pages"

import {
  homePillarsModal,
  homePillarsModalAnimation,
  homePillarsModalClose,
  homePillarsModalContentButton,
  homePillarsModalContentDescription,
  homePillarsModalContentTitle,
} from "./styles.css"

const LottiePlayer = dynamic(() => import("@app/shared/components/LottiePlayer"))

type HomePillarsModalProps = SellPillarContent & {
  onClose: () => void
}

export const HomePillarsModal: FC<HomePillarsModalProps> = ({ animation, description, onClose, title }) => {
  const { t } = useTranslation("sell")

  return (
    <div className={homePillarsModal}>
      <div className={homePillarsModalAnimation}>
        <LottiePlayer loop name={animation} />
      </div>
      <Typography className={homePillarsModalContentTitle} component="h2" variant="h2">
        {title}
      </Typography>
      <Typography className={homePillarsModalContentDescription} variant="bodyM">
        {description}
      </Typography>
      <Button className={homePillarsModalContentButton} onClick={onClose}>
        {t("sell:pillarsCta")}
      </Button>
      <div className={homePillarsModalClose} onClick={onClose}>
        <Close />
      </div>
    </div>
  )
}
