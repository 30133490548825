import dynamic from "next/dynamic"
import { FC, useEffect, useState } from "react"

import { Accordion } from "@app/shared/components/Accordion"
import { Typography } from "@app/shared/components/Typography"
import { SellPillarItem } from "@app/shared/types/website-pages"

import {
  homePillarsAccordion,
  homePillarsAccordionContentAnimation,
  homePillarsAccordionDescriptionDesktop,
  homePillarsAccordionDescriptionMobile,
} from "./styles.css"

const LottiePlayer = dynamic(() => import("@app/shared/components/LottiePlayer"))

type HomePillarAccordionProps = SellPillarItem & {
  desktopDescription: string
  isOpen: boolean
  onToggle: () => void
}

export const HomePillarAccordion: FC<HomePillarAccordionProps> = ({
  animation,
  description,
  desktopDescription,
  isOpen,
  onToggle,
  title,
}) => {
  const [lottie, setLottie] = useState<any>(null)

  const getLottie = async () => {
    const { default: _lottie } = await import(/* webpackChunkName: "lottieweb" */ "lottie-web")
    setLottie(_lottie)
  }

  useEffect(() => {
    getLottie()
  }, [])

  return (
    <Accordion
      className={`${homePillarsAccordion} ${isOpen ? "open" : ""}`}
      isOpen={isOpen}
      title={title}
      onToggle={() => {
        if (!isOpen) {
          lottie.stop(animation)
          lottie.play(animation)
        }
        onToggle()
      }}
    >
      <Typography className={homePillarsAccordionDescriptionMobile}>{description}</Typography>
      <Typography className={homePillarsAccordionDescriptionDesktop}>{desktopDescription}</Typography>
      <LottiePlayer autoplay={false} className={homePillarsAccordionContentAnimation} name={animation} />
    </Accordion>
  )
}
