import { H2, vars } from "@casavo/habitat"
import { motion } from "framer-motion"
import useTranslation from "next-translate/useTranslation"
import React, { useRef } from "react"

import { StepCardList } from "@app/shared/components/StepCardList"
import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import { useChangeBgColorInView } from "@app/shared/hooks/useChangeBgColorInView"
import { useFadeInTransition } from "@app/shared/hooks/useFadeInTransition"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

export const Steps: React.FC = () => {
  const { t } = useTranslation("sell")
  const targetRef = useRef(null)
  const { opacity, y } = useFadeInTransition({ targetRef })

  useChangeBgColorInView(targetRef, vars.colors.background.default.light, { amount: 0.5 })

  return (
    <motion.section ref={targetRef} className={sprinkles({ paddingY: "4xl" })} style={{ opacity, y }}>
      <div
        className={sprinkles({
          paddingX: "m",
          textAlign: {
            desktop: "center",
            mobile: "left",
          },
        })}
      >
        <H2 noMargin>{t("sellingsteps_header")}</H2>
      </div>
      <StepCardList
        className={sprinkles({ paddingTop: "3xl" })}
        onSwipe={(nextStepIndex) => {
          handleUserEventClick(`CarouselSellingSteps-SwipeOnCarousel0${nextStepIndex + 1}`)
        }}
      />
    </motion.section>
  )
}
